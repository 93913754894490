import React, { useState } from "react"

import LogoSVG from "../../assets/logo.svg"
import IsoSampleRaw from "../images/ravr.png"
import Logo from "../components/logo"
import Phone from "../components/phone"
import Layout from "../components/layout"
import SEO from "../components/seo"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import styled, { keyframes, css } from 'styled-components';

const upDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(30px);
  }
`

const upDownAnimation = css`
  ${upDown} 4s ease-in-out infinite alternate;
`


const Title = styled.h1`
  font-size: 3em;
  text-align: left;
  font-weight: black;
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
  line-height: 1;
`;

const SignUpText = styled.label`
  font-size: 1em;
  text-align: left;
  font-family: sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
`;

const LeftContainer = styled.div`
font-size: 1.5em;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: space-between;
  color: white;
  z-index: 2;
`;

// const RightContainer = styled.div`
//   background-color: #AC70E8;
//   width: %;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   position: sticky;
//   justify-content: center;
//   align-items: center;
//   color: white;
// `;


const Header = styled.div`
  width: 100%;
  height: 30%;
  padding: 16px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Footer = styled.div`
  padding: 25px;
  z-index: 4;
`

const ScreenshotImage = styled.img`
  animation: ${upDownAnimation};
  width: 70%;
  @media (max-width: 630px) {
    width: 95%;
  }
`

const BottomLeftContainer = styled.div`
  width: 100%;
`

const EmailFormContainer = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding: 0px;
`

const EmailForm = styled.form`
  border-radius: 100px;
  width: 100%;
  height: 100%;
  flex-direction: row;
  font-family: sans-serif;
  font-weight: 600;
  padding: 0px;
  align-items: center;
  justify-content: space-between;
`

const EmailInput = styled.input`
  height: 100%;
  margin: 0 auto;
  margin-top:10px;
  border: none;
  border:solid 0px #ccc;
  border-radius: 10px;
  margin-right: 10px;
`

const SubmitButton = styled.input`
  background-color: transparent;
  height: 100%;
  font-weight: 400;
  font-size: 0.8em;
  color: white;
  margin: 10px 0px;
  padding:6px 20px; 
  border: none;
  border: solid 2px white;
  border-radius: 100px;
`

const IndexPage = () => {
  const [email, setEmail] = useState('');

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { msg, result } = await addToMailchimp(email, {})
      console.log('msg', `${result}: ${msg}`);
      if (result !== 'success') {
        throw msg;
      }
      alert("Thanks for signing up for Ravr!  We will send you more information as development proceeds.");
    }
    catch (err) {
      console.log('err', err, email);
      alert(err);
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <div style={{ width: '100%', height: '100%', position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
        <ScreenshotImage src={IsoSampleRaw} style={{ height: '70%', objectFit: 'contain', marginTop: '8%' }} />
      </div>
      <LeftContainer>
        <Header>
          <Logo style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
        </Header>
        <Footer>
          <Title>Ravr.</Title>
          <Subtitle>Connect. Energize. Rave.</Subtitle>
          <div style={{ flexDirection: 'row', marginBottom: 25 }}>
            <EmailFormContainer>
              <EmailForm onSubmit={_handleSubmit}>
                <SignUpText for={"email"}>Join the Waitlist. <b>7k and Counting.</b> </SignUpText>
                <EmailInput placeholder={' email@hello.com'} type="email" id="email" onChange={(event) => { setEmail(event.target.value.toLowerCase()) }} />
                <SubmitButton type="submit" />
              </EmailForm>
            </EmailFormContainer>
          </div>
        </Footer>
      </LeftContainer>
    </Layout>
  )
}

export default IndexPage
